var category="all";

document.addEventListener('DOMContentLoaded',function(){
	var categoryArray=[];//カテゴリjson格納
	var listArray=[];//リストjson格納
	var device='pc';
	var prevDevice;

	if(window.innerWidth<=768){
		prevDevice='pc';
	}
	else {
		prevDevice='sp';
	}
	getJsonList();
	window.addEventListener('resize',function(){
		if(window.innerWidth<=768){
			device='pc';
		}
		else {
			device='sp';
		}
		if(prevDevice!=device){
			location.reload();
		}
	})
})
const irArray=[];

window.ir20handler = data => {
	Array.prototype.forEach.call(data.item,function(item){
		irArray.push(item);
	})
}
/*
const init = async () => {

}*/
getIrData();

function waitGetIrData(){
	if(window.innerWidth<=768){
		prevDevice='pc';
	}
	else {
		prevDevice='sp';
	}
	getJsonList(irArray);
	window.addEventListener('resize',function(){
		if(window.innerWidth<=768){
			device='pc';
		}
		else {
			device='sp';
		}
		if(prevDevice!=device){
			location.reload();
		}
	})
}

async function getIrData() {
	try {
		const data = await fetch('https://xml.irpocket.com/1861/JS/release-all-all.js');
		const text = await data.text();
		eval(text);
		waitGetIrData();
	}catch(error){
		console.log(error);

	}
}


function resetDevice() {
	window.addEventListener('resize',function(){
		resetDevice();
	})

	if(prevDevice!=device){
		window.location.reload();
	}
}

function getJsonList(){
	async function fetchURLs(urls) {
		try {
			const fetchUrls = urls.map(url => fetch(url).then(res => res.json()))
			const [category, list] = await Promise.all(fetchUrls)
			categoryArray=category.data[0].news_categories;
			listArray=list.data;
			listArray=listArray.filter(function(item){
				if (item.how_to_post == 'both' || item.how_to_post == 'list-only') return true;
			})
			pushIrData(irArray);
			sortListArray();
			setRadioCategory();//カテゴリradioの設定
			filterData();
		} catch (error) {
			console.error(error)
		}
	}
	fetchURLs([
		'/news/json/category.json',
		'/news/json/list.json'
	])
}

function pushIrData(irArray){
	const listArrayLength=listArray.length;

	Array.prototype.forEach.call(irArray,function(item,index){
		var tempItem={};

		tempItem.id=Number(listArrayLength + index);
		tempItem.create_date=String(item.published);
		tempItem.title=String(item.title);
		tempItem.how_to_post='both';
		tempItem.url=String(item.link);
		if(item.target=='_blank'){
			tempItem.target_blank='true';
		}
		else {
			tempItem.target_blank='false';
		}
		tempItem.news_category={};
		tempItem.news_category.name='IR';
		tempItem.news_category.basename='ir';
		listArray.push(tempItem);
	})
}

function sortListArray() {
	listArray = listArray.sort((a, b) => Date.parse(b.create_date) - Date.parse(a.create_date));
}


//カテゴリのradioを設定
function setRadioCategory(){

	var element=document.querySelector('.js-news-category');
	var tempHTML='<li><input type="radio" name="category" id="categoryAll" name="categoryAll" value="all" ';

	tempHTML+='checked';

	tempHTML+='><label for="categoryAll">すべて</label></li>';
	if(element){
		Array.prototype.forEach.call(categoryArray,function(item,index){
			tempHTML+='<li><input type="radio" name="category" id="category'+String(index)+'" name="category'+String(index)+'" value="'+item.basename+'" ';
			tempHTML+='><label for="category'+String(index)+'">'+item.name+'</label></li>';
		})
	}
	tempHTML+='<li><input type="radio" name="category" id="category0'+String(categoryArray.length + 1)+'" value="ir" name="category0'+String(categoryArray.length + 1)+'" ';
	tempHTML+='><label for="category0'+String(categoryArray.length + 1)+'">IR</label></li>';
	element.innerHTML=tempHTML;

	var trigger=element.querySelectorAll(':scope > li > input[type="radio"]');
	Array.prototype.forEach.call(trigger,function(item){
		item.addEventListener('click',function(){
			category=this.getAttribute('value');
			filterData();
		})
	})
}


function filterData(){
	var temp_data=listArray;
	var tempArray=[];

	tempArray=temp_data;

	if(category!='all'){
		tempArray=temp_data.filter(function(item){
			if (item.news_category.basename == category) return true;
		})
	}
	else {
		tempArray=temp_data;
	}
	setResult(tempArray);//news記事をdom出力
}


var height_array=[]//要素高さ格納用
var trigger_Flag=false;
var counter=0;//もっと見るボタンの押下回数

var moreWrapper;
//検索結果DOM生成
function setResult(dataArray){
	var element = document.querySelector('.js-news-list');
	if(element){
		var tempHTML='';
		var parent=element.querySelector('.js-set-data');
		moreWrapper=element.parentNode;
		counter=0;
		var children;//出力された子要素
		height_array=[];


		Array.prototype.forEach.call(dataArray,function(item,index){
			if(index<5){
			var year=item.create_date.split('-')[0];
			var month=item.create_date.split('-')[1];
			var day=item.create_date.split('-')[2].split(' ')[0];

			var today=new Date();
			today=String(today.getFullYear()) + String(("00" + (today.getMonth()+1)).slice(-2)) + String(("00" + (today.getDate())).slice(-2));
			tempHTML+='<li>';
			tempHTML+='<a href="'+item.url+'"';
			if(item.target_blank=="true"){
				tempHTML+=' target="_blank"';
			}
			tempHTML+='>'
			tempHTML+='<div class="p-top-news__ttlArea">';
			tempHTML+='<p class="p-top-news__ttl">'+item.news_category.name+'</p>';
			tempHTML+='<p class="p-top-news__date">'+year+'年'+month+'月'+day+'日';

			if(Number(year+month+day)>Number(today) - 7){
				tempHTML+='<i class="c-icon c-icon--new"></i>';
			}

				if(item.url.indexOf('pdf')!=-1){
					tempHTML+='<i class="c-icon c-icon--pdf"></i>';
				}
				else {
					if(item.target_blank=="true"){
						tempHTML+='<i class="c-icon c-icon--blank"></i>';
					}
					if(item.url.indexOf('pdf')!=-1){
						tempHTML+='<i class="c-icon c-icon--pdf"></i>';
					}
				}


			tempHTML+='</p>';
			tempHTML+='</div>';
			tempHTML+='<p class="p-top-news__txt">'+item.title+'</p>';
			tempHTML+='</a>'
			tempHTML+='</li>';
			}
		})
		element.innerHTML=tempHTML;
	}
}


